/* Custom Fonts */
@font-face {
  font-family: Centra;
  src: url('../assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;

  font-family: Centra;
  src: url('../assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;

  font-family: Centra;
  src: url('../assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #303030 !important;
  font-family: 'Centra', sans-serif !important;
  overscroll-behavior-y: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #303030
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

.success {
	color: green;
}

.danger {
	color: red;
}
/************ Navbar CSS ************/
nav.navbar {
  padding: 1.125rem 0.75rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0.2rem 0.75rem;
  background-color: #DDF0F4;
}
nav.navbar a.navbar-brand {
  width: auto;
  max-height: 1rem;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #505050 !important;
  letter-spacing: 0.01rem;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 15px;
}
.social-icon a {
    width: 1.5rem;
    height: 1.5rem;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-right: 0.5rem;
    border: 1px solid rgba(100, 100, 100, 0.9);
}
.social-icon a::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    background-color: #F3FDFF;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(70%);
}
.navbar-text button {
  font-weight: 700;
  color: #505050;
  padding: 1rem 1rem;
  font-size: 0.75rem;
  margin-left: 1rem;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #505050;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #505050;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #505050;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #505050;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #505050;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #505050;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
/************ Banner CSS ************/
.banner {
  margin-top: 0;
  padding: 9rem 0 17rem;
  background-color: #E6FAFF;
}
.banner h1 {
  color: #303030;
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.001rem;
  line-height: 1.3;
  margin-bottom: 2rem;
  display: block;
}
.banner p {
  color: #505050;
  font-size: 0.80rem;
  letter-spacing: 0.001rem;
  line-height: 1.5em;
  width: 100%;
}
.banner .portrait-img {
  border-radius: 50%;
  object-fit: cover;
  width: 3rem;
  height: 3rem;
  margin-bottom: 2rem;
}
.banner .banner-img {
  height: 18rem;
  width: 18rem;
  animation: updown 5s linear infinite;
}
.banner .link {
  margin-bottom: 0.5rem;
  color: lightskyblue;
  font-size: 0.90rem;
  letter-spacing: 0.001rem;
  line-height: 1.5em;
  width: 100%;
  text-decoration: none;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
@keyframes blink {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}

.banner .blink {
  animation: blink 1s linear infinite;
  color: black;
}
/************ Skills CSS ************/
.skill {
    background-color: #E6FAFF;
    position: relative;
    text-align: center;
    padding: 3rem 1rem;
    margin-top: -5rem;
}
.skill h2 {
    font-size: 1.25rem;
    font_weight: 700;
    color: #303030
}
.skill h5 {
    font-size: 0.9rem;
    font_weight: 700;
    color: #303030
}
.skill p {
    color: #505050;
    font-size: 0.80rem;
    letter-spacing: 0.03125rem;
    line-height: 1.5em;
    margin: 0.875rem 0 4.6875rem;
}
.skill-slide {
    width: 80%;
    margin: 0 auto;
    position: relative;
}
.skill-slider .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.skill .divider {
  height: 1px;
  width: 80%;
  background-color: #B6B6B6;
  opacity: 0.15;
  position: relative;
  margin: 6rem auto;
}
@property --progress-value {
  syntax: '<integer>';
  inherits: false;
  initial-value: 0;
}
.skill .progress-bar {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E6FAFF;
  margin-bottom: 1rem;
}
.skill .progress-bar::before {
  font-size: 1.2rem;
  counter-reset: percentage var(--progress-value);
  content: counter(percentage) '%';
}
@keyframes python-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 99;
  }
  80%{
    --progress-value: 99;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .python {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: python-progress 4s infinite;
}
.skill .python::before {
  animation: python-progress 4s infinite;
}
@keyframes pandas-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 99;
  }
  80%{
    --progress-value: 99;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .pandas {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: pandas-progress 4s infinite;
}
.skill .pandas::before {
  animation: pandas-progress 4s infinite;
}
@keyframes react-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 72;
  }
  80%{
    --progress-value: 72;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .react {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: react-progress 4s infinite;
}
.skill .react::before {
  animation: react-progress 4s infinite;
}
@keyframes htmlcss-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 92;
  }
  80%{
    --progress-value: 92;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .htmlcss {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: htmlcss-progress 4s infinite;
}
.skill .htmlcss::before {
  animation: htmlcss-progress 4s infinite;
}
@keyframes stats-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 87;
  }
  80%{
    --progress-value: 87;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .stats {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: stats-progress 4s infinite;
}
.skill .stats::before {
  animation: stats-progress 4s infinite;
}
@keyframes dl-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 77;
  }
  80%{
    --progress-value: 77;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .dl {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: dl-progress 4s infinite;
}
.skill .dl::before {
  animation: dl-progress 4s infinite;
}
@keyframes ae-progress {
  10% {
    --progress-value: 0;
  }
  40%{
    --progress-value: 91;
  }
  80%{
    --progress-value: 91;
  }
  100% {
    --progress-value: 0;
  }
}
.skill .ae {
  background:
    radial-gradient(closest-side, #E6FAFF 79%, transparent 80%),
    conic-gradient(lightblue calc(var(--progress-value) * 1%), #F3FDFF 0);
  animation: ae-progress 4s infinite;
}
.skill .ae::before {
  animation: ae-progress 4s infinite;
}
/************ Contact CSS ************/
.contact {
  background: #E6FAFF;
  padding: 5rem 0;
}
.contact h2 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(50, 50, 50, 0.8);
  border-radius: 15px;
  color: #505050;
  margin-bottom: 0.5rem;
  padding: 1rem 1.2rem;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.001rem;
  transition: 0.3s ease-in-out;
}
/* Media query for iPhone screen sizes */
@media screen and (max-width: 576px) {
  .contact form input,
  .contact form textarea {
    width: 80%;
  }
}
.tight-padding {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 0.9);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 0.75rem;
  font-weight: 400;
  color: #505050;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #F3FDFF;
  padding: 0.875rem 2.5rem;
  font-size: 0.75rem;
  margin-top: 1rem;
  border-radius: 30px;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border: none;
}
.contact form button span {
  z-index: 2;
  position: relative;
}
.contact form button:hover {
  color: #FFF;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 30px;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}
.contact .divider {
  height: 1px;
  width: 80%;
  background-color: #B6B6B6;
  opacity: 0.15;
  position: relative;
  margin: 6rem auto;
}
.contact .image {
  width: 18rem;
  height: 18rem;
  animation: updown 5s linear infinite;
  margin-bottom: 5rem;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
/************ Footer CSS ************/
.footer {
  box-sizing: border-box;
  padding: 2rem 0;
  background: #E6FAFF;
  color: #B8B8B8;
  width: 100%;
  overflow: hidden;
}
.footer p {
  font-size: 0.75rem;
  width: 100%;
  margin-top: 2rem;
}
.footer .divider {
  background-color: #B6B6B6;
  opacity: 10%;
  height: 1px;
}


